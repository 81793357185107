<template>
  <div class="d-flex flex-column flex-root vh-100">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
        id="kt_login"
    >
      <!--begin::Aside-->
      <div
          class="login-aside d-flex flex-column flex-row-auto"
          style="background-color: #110f38;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <!-- <img src="media/logos/logo2.png" class="max-h-90px" alt=""/> -->
          </a>
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: white;">
            WiseDesk <br> 
          </h3>
        </div>
        <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form" @submit.stop.prevent="onSubmitLogin()">
              <div class="pb-13 pt-lg-0 pt-5">

                <h3 class="font-weight-bolder text-center text-dark font-size-h4 font-size-h1-lg">
                  WiseDesk
                </h3>


              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                >Acesso</label
                >
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      name="email"
                      ref="email"
                      v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Senha</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      name="password"
                      ref="password"
                      v-model="form.password"
                      autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                    :class="{ 'spinner spinner-light spinner-right': loading }"
                    :disabled="loading"
                >
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import {mapGetters, mapState} from "vuex";
import {LOGIN, LOGOUT} from "@/core/store/auth.module";
import Swal from "sweetalert2";

import {getUsuario} from "@/core/services/usuario.service";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      loading: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    ...mapGetters(['currentUser']),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {

   var   novo_form = {
        login:this.form.email,
        senha:this.form.password
      }

      this.$store.dispatch(LOGOUT);
      this.loading = true;
      this.$store.dispatch(LOGIN, novo_form)
          .then(() => {
             var aluno = JSON.parse(localStorage.getItem("ucpUsrInf"));
            if(aluno.tipo === "sistemico" || aluno.tipo === "administrador"){
              this.$router.push({name: "dashboard"})
            };
            if(aluno.tipo === "atendente"){
              this.$router.push({name: "atendente"})
            }

          })
        
          .catch(() => {
            Swal.fire({
              title: 'Dados incorretos!',
              text: 'Confira suas credenciais e tente novamente.',
              icon: "error"
            })
          })
          .finally(() => this.loading = false)
    },
  }
};

</script>
